import React from 'react'

import { DropdownFilter } from '../components/DropdownFilter'

import { Select, SelectOption } from './Select'

import { select } from './FilterDropdown.module.css'

export function FilterDropdown (props) {
  const {
    filterKey,
    filters = [],
    tableFilters,

    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  } = props

  const handleSearch = (selectedKeys, confirm) => {
    if (tableFilters) {
      tableFilters[filterKey] = selectedKeys
    }
    confirm()
  }

  const handleReset = clearFilters => {
    clearFilters()
  }

  const onChange = e => {
    const value = e.target ? e.target.value : e
    setSelectedKeys(value ? [value] : [])
  }

  return (
    <DropdownFilter
      handleFilter={() => handleSearch(selectedKeys, confirm, filterKey)}
      handleReset={() => handleReset(clearFilters)}
    >
      <Select
        data-testid={`select-${filterKey}`}
        showSearch
        className={select}
        placeholder={'Country'}
        value={selectedKeys[0] || ''}
        onChange={onChange}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.stopPropagation()
            handleSearch(selectedKeys, confirm, filterKey)
          }
        }}
      >
        {filters.map(({ value, text: label }) => (
          <SelectOption
            key={label}
            value={value?.toString()}
            data-testid="select-option"
          >
            {label}
          </SelectOption>
        ))}
      </Select>
    </DropdownFilter>
  )
}
