import React, { useRef, useEffect, useState, useMemo } from 'react'

import { FolderOpenFilled } from '@ant-design/icons'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

import { jobApplicationStages } from '../../redesign/components/ApplicationStageTag/constants'
import { Button } from '../Button'
import { appStatusToFields, appStatusOptions } from '../JobStatusCell'
import { message } from '../Message'
import { Pagination } from '../Pagination'
import { RadioGroup } from '../RadioGroup'
import SearchFilters from '../Search/SearchFilters'
import { SimplePagination } from '../SimplePagination'
import { Table } from '../Table'

import { updateJobApplication } from '../../services/jobApplications'
import { advancedSearch } from '../../services/search'

import { useCampaigns } from '../../hooks/useCampaigns'
import { useDynamicTable } from '../../hooks/useDynamicTable'
import { useFixedColumn } from '../../hooks/useFixedColumn'
import { useIsMounted } from '../../hooks/useIsMounted'
import { useLocalStorage } from '../../hooks/useLocalStorage'

import { SEARCH_FILTER_PRESETS } from '../../utils/constants'
import {
  updateQueryString,
  applySorter,
  prepareSortItem
} from '../../utils/filter-helpers'
import {
  handleTableRowClick,
  getArrayWithOpenRoleCandidates
} from '../../utils/helpers'
import {
  getAppStageToFields,
  getJobApplicationStatusFilters,
  getStageTooltipAddedJobApplications
} from '../../utils/job-application-helpers'
import { trimEmptyParams } from '../../utils/params'
import {
  getColumns,
  getActiveFilters,
  calculateSearchFiltersDefaults
} from '../../utils/result-table-helpers'
import {
  generalFilters as getGeneralFilters,
  tableFilters
} from '../../utils/search'
import { skillFilterNames } from '../../utils/search/filters'
import { FILTER_NAMES as tagFilterNames } from '../../utils/search/filters/general/tags'
import { FILTER_NAMES as fullNameFilterNames } from '../../utils/search/filters/table/full-name'

import * as styles from './ResultsTable.module.css'

const defaultJobsSortKey = 'appliedAt'
const defaultJobsSortParam = `${defaultJobsSortKey}:ASC`
const defaultJobFilterPreset = 'reset'

function shouldPerformSearchOperation ({ searchFilters, query }) {
  const availableSearchQueryParams = []
  Object.keys(searchFilters).forEach(key => {
    const filterSpecDefaultValues = searchFilters[key].spec.defaultValues
    availableSearchQueryParams.push(...Object.keys(filterSpecDefaultValues))
  })

  return Object.keys(query).some(param =>
    availableSearchQueryParams.includes(param)
  )
}

export default function ResultsTable ({
  jobId,
  partnerRoleId,
  renderScreenHeader,
  skillsById,
  tagsById,
  industriesById,
  screeningAuthorsById,
  techEvalAuthorsById,
  jobTypesById,
  features,
  type
}) {
  const isMounted = useIsMounted()
  // Old search UI is only shown on the jobs page
  const hasOldSearchView = !jobId || partnerRoleId
  const isUnifiedSearchUrl = Boolean(features?.UNIFIED_SEARCH_URL)
  const isSuccessfulStateEnabled = Boolean(features?.RELEASE_SUCCESSFUL_STATE)
  const isMonthsExperienceSkillsEnabled = Boolean(
    features?.RELEASE_MONTHS_EXPERIENCE_SKILLS
  )

  const { marketingCampaigns } = useCampaigns()
  const [clear, setClear] = useState(false)
  const [isNonCollapseSearchView, setIsNonCollapseSearchView] =
    useState(hasOldSearchView)
  const nameFilterInputRef = useRef(null)
  const { isFixedColumn } = useFixedColumn()
  const searchFilters = useMemo(
    () => ({
      ...tableFilters({
        isFixedColumn,
        isUnifiedSearchUrl,
        isMonthsExperienceSkillsEnabled
      }),
      ...getGeneralFilters()
    }),
    [isFixedColumn, isUnifiedSearchUrl, isMonthsExperienceSkillsEnabled]
  )
  const [localStorageValue, setLocalStorageValue] = useLocalStorage('origin')

  useEffect(() => {
    setIsNonCollapseSearchView(hasOldSearchView)
  }, [hasOldSearchView])

  useEffect(() => {
    message.config({
      maxCount: 1
    })
  }, [])

  /**
   * Resets all the search filters and sorters.
   */
  const resetSearchFilters = () => {
    const filters = calculateSearchFiltersDefaults(searchFilters)
    setFilters(filters, [])
  }

  const defaultFilters = calculateSearchFiltersDefaults(searchFilters)

  const resetFilters = () => {
    // Resets to default filters preset
    handleJobSearchPresetSelection(defaultJobFilterPreset)

    // Update URL address by trimming query params
    updateQueryString({})

    // Notify children filters to reset its selectors to defaults
    setClear(!clear)
  }

  const presetSelectionUINotification = preset =>
    message.success({
      content: preset
        ? `Search reset to "${preset}" preset`
        : 'Filters Reset Successfully',
      maxCount: 1
    })

  const skillListValues = Object.values(skillsById)

  const getFilterOptionList = filterKey => {
    let filterOptionListValues = []
    switch (filterKey) {
      case skillFilterNames.regular:
      case skillFilterNames.optional:
        filterOptionListValues = skillListValues
        break
      default:
    }
    return filterOptionListValues
  }

  const handleJobSearchPresetSelection = (preset, filterValue) => {
    switch (SEARCH_FILTER_PRESETS[preset]) {
      case SEARCH_FILTER_PRESETS.orderByExpiry: {
        const sortQueryParamValue = jobId ? defaultJobsSortParam : ''

        // Handle querystring and refresh data based on filter
        resetSearchFilters()

        applySorter({
          sortQueryParamValue,
          setSorter,
          query
        })

        presetSelectionUINotification(SEARCH_FILTER_PRESETS[preset])
        break
      }

      case SEARCH_FILTER_PRESETS.orderByExperience: {
        applySorter({
          sortQueryParamValue: '',
          setSorter,
          query
        })

        const confirmedSkills = filterValue.filter(({ confirmed }) => confirmed)

        // Programmatically apply "Skills" filter
        // and handle the querystring update.
        setFilters({
          ...calculateSearchFiltersDefaults(searchFilters),
          skills: confirmedSkills.map(({ id }) => id.toString()),
          optionalSkills: confirmedSkills
            .filter(({ id }) =>
              filters[skillFilterNames.optional].includes(id.toString())
            )
            .map(({ id }) => id.toString())
        })

        presetSelectionUINotification(SEARCH_FILTER_PRESETS[preset])
        break
      }

      case SEARCH_FILTER_PRESETS.reset: {
        // Reset all filters
        resetSearchFilters()

        presetSelectionUINotification()
        break
      }

      default:
        throw new TypeError(`${preset} search preset is not implemented yet.`)
    }
  }

  // Job
  if (jobId) {
    Object.assign(defaultFilters, {
      appStatus: 'pending'
    })
  }

  // Querystring
  const location = useLocation()
  const query = queryString.parse(location.search || '')

  const shouldPerformSearch = shouldPerformSearchOperation({
    searchFilters,
    query
  })

  // Comes into effect on page refresh when reading URL query params
  const queryToFilter = {
    _start: val => val,
    _limit: val => val
  }

  Object.keys(searchFilters).forEach(filterKey => {
    const execFn =
      searchFilters[filterKey].spec.queryString.populateFilterDefaultValues
    if (typeof execFn !== 'function') {
      return
    }

    Object.assign(queryToFilter, execFn(getFilterOptionList(filterKey)))
  })

  // Job
  if (jobId) {
    Object.assign(queryToFilter, {
      hiddenAt_null: val => val,
      appStatus: val => val,
      reviewers: val => val
    })
  }

  Object.keys(query).forEach(key => {
    const val = query[key]
    const func = queryToFilter[key]
    if (!func) {
      return
    }

    let filterVal = func(val)

    if (key === skillFilterNames.regular || key === skillFilterNames.optional) {
      filterVal = filterVal.reduce((result, skill) => {
        if (skillsById[skill]) {
          result.push(skill)
        } else {
          console.warn('Skill not found:', skill)
        }

        return result
      }, [])
      defaultFilters[key] = filterVal
    } else if (
      key === tagFilterNames.regular ||
      key === tagFilterNames.optional
    ) {
      filterVal = filterVal.reduce((result, tag) => {
        if (tagsById[tag]) {
          result.push(tag)
        } else {
          console.warn('Tag not found:', tag)
        }

        return result
      }, [])
      defaultFilters[key] = filterVal
    } else if (typeof filterVal === 'object' && !Array.isArray(filterVal)) {
      Object.assign(defaultFilters, filterVal)
    } else if (
      Array.isArray(filterVal) &&
      key === fullNameFilterNames.optional
    ) {
      defaultFilters.fullName = filterVal
    } else {
      defaultFilters[key] = filterVal
    }
  })

  // By default, the applications nearest to expiration go at the top
  const defaultSort = prepareSortItem(
    query._sort || (jobId ? defaultJobsSortKey : '')
  )

  const {
    data,
    loading,
    pagination,
    setPagination,
    filters,
    setFilters,
    sorter,
    setSorter,
    onTableChange,
    onPaginationChange,
    fetchPage
  } = useDynamicTable({
    defaultFilters,
    defaultSort,

    filterToFetchParam: Object.assign(
      {},
      ...Object.keys(searchFilters).map(filterKey => {
        const execFn = searchFilters[filterKey].spec.request.paramsSerializer
        if (typeof execFn !== 'function') {
          return false
        }

        return execFn()
      })
    ),

    fetch: async (params, { filters }, cancelToken) => {
      const appStatusObj = isSuccessfulStateEnabled
        ? getAppStageToFields(filters.appStatus)
        : appStatusToFields[filters.appStatus] || {}

      if (!isSuccessfulStateEnabled && filters.appStatus === 'closed') {
        delete appStatusObj.hiddenAt_null
      }

      const customParams = {
        job: jobId,
        ...appStatusObj,

        _start: params._start,
        _limit: params._limit
      }

      Object.keys(searchFilters).forEach(filterKey => {
        const execFn =
          searchFilters[filterKey].spec.request.customParamsSerializer
        const transformFn =
          searchFilters[filterKey].spec.request.transformParams

        if (typeof execFn === 'function') {
          Object.assign(customParams, execFn(filters))
        }

        if (typeof transformFn === 'function') {
          transformFn(params)
        }
      })

      Object.assign(params, trimEmptyParams(customParams))

      const advancedSearchFunc = advancedSearch

      const res = await advancedSearchFunc({
        cancelToken: cancelToken.token,
        params
      })
      const { total } = res.data
      setPagination(pagination => ({
        ...pagination,
        total
      }))

      if (!partnerRoleId) {
        // Mirror the search params in the querystring
        const queryParams = {
          _start: params._start,
          _limit: params._limit
        }

        Object.keys(searchFilters).forEach(filterKey => {
          const execFn =
            searchFilters[filterKey].spec.queryString.paramsSerializer

          if (typeof execFn !== 'function') {
            return
          }

          Object.assign(
            queryParams,
            execFn(
              params,
              filters,
              isUnifiedSearchUrl,
              getFilterOptionList(filterKey)
            )
          )
        })

        if (params.job) {
          Object.assign(queryParams, {
            id: params.job,
            hiddenAt_null: filters.hiddenAt_null,
            appStatus: filters.appStatus,
            reviewers: params.reviewers
          })
        }

        // Also mirror sorter
        queryParams._sort = Array.isArray(params._sort)
          ? params._sort.join(',')
          : params._sort

        updateQueryString(queryParams)
      }
      if (jobId) {
        let dataWithOpenRoleCandidates = await getArrayWithOpenRoleCandidates(
          res.data.results,
          item => item.id,
          isSuccessfulStateEnabled
            ? { populate: ['bookedBy', 'partnerRole'] }
            : {}
        )

        if (isSuccessfulStateEnabled) {
          dataWithOpenRoleCandidates = dataWithOpenRoleCandidates.map(item => {
            if (
              item?.stage === jobApplicationStages.successful.value &&
              item?.booking
            ) {
              item.booking = item?.roleCandidates?.find(
                ({ id }) => id === item?.booking
              )
            }

            return item
          })

          dataWithOpenRoleCandidates = getStageTooltipAddedJobApplications(
            dataWithOpenRoleCandidates
          )
        }

        return {
          ...res,
          data: {
            ...res.data,
            results: dataWithOpenRoleCandidates
          }
        }
      }

      return res
    },

    defaultPagination: () => {
      const pageSize = defaultFilters._limit || 50
      const current =
        defaultFilters._start > 0 ? defaultFilters._start / pageSize + 1 : 1
      return {
        pageSize,
        current,
        total: null
      }
    },

    lazy: (!jobId && !shouldPerformSearch) || (!jobId && isMounted())
  })

  // Job

  if (!industriesById || !marketingCampaigns) {
    return 'loading...'
  }

  const onHide = async (event, jobApplicationId) => {
    event.stopPropagation()
    try {
      await updateJobApplication(jobApplicationId, {
        hiddenAt: new Date()
      })
      onTableChange({}, {}, sorter)
    } catch (error) {
      console.error(error)
    }
  }

  const onChangeAppStatus = event => {
    const { value } = event.target
    setFilters(getJobApplicationStatusFilters(value))
  }

  const columns = getColumns({
    searchFilters,
    jobId,
    tagsById,
    skillsById,
    industriesById,
    nameFilterInputRef,
    isNonCollapseSearchView,
    sorter,
    filters,
    jobTypesById,
    features,
    partnerRoleId,
    onHide
  })

  if (isNonCollapseSearchView) {
    columns.forEach(column => (column.width = 200))
  }

  // Calculate active filters to render in the "Active filters" bar
  const activeFilters = getActiveFilters(searchFilters, filters)
  const shouldRenderActiveFilters = activeFilters.length > 0

  const emptyTableTextElement = (emptyText = 'No Data') => (
    <div className={styles.empty}>
      <div className={styles.emptyImage}>
        <FolderOpenFilled />
      </div>
      <div className={styles.empty}>{emptyText}</div>
    </div>
  )

  return (
    // Apply 'content' css class only if its search page and collapsible search view.
    <div className={!jobId && !isNonCollapseSearchView ? 'content' : ''}>
      {renderScreenHeader &&
        renderScreenHeader({ handleJobSearchPresetSelection, filters })}
      {!partnerRoleId && (
        <SearchFilters
          pagination={pagination}
          filters={filters}
          sorter={sorter}
          fetchPage={fetchPage}
          setFilters={setFilters}
          isJob={Boolean(jobId)}
          clear={clear}
          skillsById={skillsById}
          tagsById={tagsById}
          industriesById={industriesById}
          screeningAuthorsById={screeningAuthorsById}
          techEvalAuthorsById={techEvalAuthorsById}
          jobTypesById={jobTypesById}
          isNonCollapseSearchView={isNonCollapseSearchView}
          resetFilters={resetFilters}
          shouldRenderActiveFilters={shouldRenderActiveFilters}
          lazy={!jobId}
          loading={loading}
        />
      )}

      {partnerRoleId && (
        <div>
          <RadioGroup
            options={appStatusOptions}
            optionType="button"
            buttonStyle="solid"
            value={filters.appStatus}
            onChange={onChangeAppStatus}
            style={{ marginBottom: '1rem' }}
          />
        </div>
      )}

      <div className={isNonCollapseSearchView ? 'content' : ''}>
        {isNonCollapseSearchView
          ? (
            <SimplePagination onChange={onPaginationChange} {...pagination} />
          )
          : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {shouldRenderActiveFilters && (
                <div style={{ display: 'flex', padding: '0.5rem' }}>
                  <div>
                    <label style={{ paddingRight: '0.5rem' }}>
                    Active Filters:{' '}
                    </label>
                  </div>

                  {activeFilters}
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto'
                }}
              >
                <div
                  style={{
                    padding: '1rem',
                    paddingRight:
                    data.results && data.results.length > 0 ? '1rem' : '0'
                  }}
                >
                  <Button
                    onClick={resetFilters}
                    disabled={!shouldRenderActiveFilters}
                  >
                  Reset Filters
                  </Button>
                </div>
                <div>
                  <Pagination onChange={onPaginationChange} {...pagination} />
                </div>
              </div>
            </div>
          )}

        <Table
          data-testid="searchResultsTable"
          locale={{
            emptyText:
              data.results === undefined
                ? emptyTableTextElement(
                  'Click the Search button to view results'
                )
                : emptyTableTextElement()
          }}
          columns={columns}
          size="small"
          scroll={{ x: true }}
          rowKey={jobId ? 'jobApplicationId' : 'id'}
          key={new Date().toString()}
          dataSource={data.results}
          loading={loading}
          onChange={onTableChange}
          onRow={record => ({
            onClick: event => {
              event.stopPropagation()
              setLocalStorageValue({
                ...localStorageValue,
                [record.id]: {
                  type,
                  record,
                  url: window.location.href
                }
              })
              handleTableRowClick(`/profile?id=${record.id}`)(event)
            },
            onContextMenu: () => {
              setLocalStorageValue({
                ...localStorageValue,
                [record.id]: {
                  type,
                  record,
                  url: window.location.href
                }
              })
            }
          })}
          pagination={false}
          rowClassName="results-table-row"
        />
        {isNonCollapseSearchView
          ? (
            <SimplePagination onChange={onPaginationChange} {...pagination} />
          )
          : (
            <Pagination onChange={onPaginationChange} {...pagination} />
          )}
      </div>
    </div>
  )
}
