import moment from 'moment'

import { jobApplicationStages } from '../redesign/components/ApplicationStageTag/constants'

export const getExpirationFormatted = jobApplication => {
  const { appliedAt } = jobApplication

  const today = moment()
  const appliedAtMoment = moment(appliedAt)
  const expiresAtMoment = appliedAtMoment.add(7, 'days')
  const difference = expiresAtMoment.diff(today, 'days')
  const inPast = difference < 0

  if (!appliedAt || inPast) {
    return '-'
  }

  return today.to(expiresAtMoment)
}

export const getJobApplicationStatusFilters = filter => ({
  ...(filter !== 'all' && filter !== 'closed'
    ? { hiddenAt_null: filter !== 'hiddenAt' }
    : { hiddenAt_null: null }
  ),
  appStatus: filter,
})

const appStageToFields = {
  pending: {
    hiddenAt_null: true,
    'stage[]': ['Pending']
  },

  closed: {
    hiddenAt_null: true,
    'stage[]': ['Expired', 'Rejected', 'Withdrawn', 'Successful'],
  },

  hiddenAt: {
    hiddenAt_null: false,
  },
}

export const getAppStageToFields = filterValue => appStageToFields[filterValue] || {}

export const getStageTooltipAddedJobApplications = jaList => jaList?.map(ja => {
  if (ja?.stage === jobApplicationStages.successful.value && ja?.booking) {
    ja.stageTooltipTitle = 'Booked'
    const partnerRoleName = ja?.booking?.partnerRole?.name

    if (partnerRoleName) {
      ja.stageTooltipTitle += ` for ${partnerRoleName}`
    }

    const bookedBy = ja?.booking?.bookedBy?.username

    if (bookedBy) {
      ja.stageTooltipTitle += ` by ${bookedBy}`
    }

    if (ja?.booking?.bookedAt) {
      const bookedAt = moment(ja.booking.bookedAt).format('YYYY-MM-DD')
      ja.stageTooltipTitle += ` on ${bookedAt}`
    }
  }

  return ja
})
