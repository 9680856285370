import React from 'react'

import { Link } from 'gatsby'
import moment from 'moment'
import PropTypes from 'prop-types'

import { Tag } from '../Tag'
import { Tooltip } from '../Tooltip'

import { getInDaysFormat } from '../../utils/runway-helpers'

import { profileStatuses } from './constants'

import * as styles from './StatusTag.module.css'

const StatusTag = ({ status, candidate, activeRunway = null }) => {
  const statusText = typeof status === 'string' ? status : status?.status || profileStatuses.ready.value

  const getTooltipTitle = () => {
    if (activeRunway) {
      const today = moment().format('YYYY-MM-DD')

      if (activeRunway.endAt) {
        const daysToEndFromToday = moment(activeRunway.endAt).diff(today, 'days')
        return `Runway ending in ${getInDaysFormat(daysToEndFromToday)}`
      }

      const daysToStartFromToday = moment(activeRunway.startAt).diff(today, 'days')

      if (daysToStartFromToday > 0) {
        return `Runway to start in ${getInDaysFormat(daysToStartFromToday)}`
      }

      if (daysToStartFromToday === 0) {
        return `Runway starting today`
      }
    }

    if (candidate?.partnerRole) {
      return (
        <Link
          to={`/role?id=${candidate?.partnerRole?.id}`}
          style={{ color: 'white' }}
        >
          {profileStatuses[statusText].text} for the{' '}
          {candidate?.partnerRole?.partner?.name}-
          {candidate?.partnerRole?.name}
          {candidate?.assignee?.username
            ? ` by ${candidate?.assignee?.username}`
            : ''}
        </Link>
      )
    }

    return null
  }

  return (
    <Tooltip
      title={getTooltipTitle()}
    >
      <div data-testid="status-tag" >
        <Tag className={styles[statusText]}>
          {profileStatuses[statusText]?.icon}
          &nbsp;
          {profileStatuses[statusText]?.text}
        </Tag>
      </div>
    </Tooltip>
  )
}

StatusTag.defaultProps = {
  status: profileStatuses.ready
}

StatusTag.propTypes = {
  /*
   * Profile Status to be rendered
   */
  status: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      status: PropTypes.oneOf(['reserved', 'clientInterviewed', 'accepted', 'booked', 'soon', 'out'])
    })
  ]),

  /*
   * Linked Candidate if existent
   */
  candidate: PropTypes.object
}

export default StatusTag
