import React from 'react'

import moment from 'moment'

import { Tooltip } from '../Tooltip'

import type { IRelativeDateProps } from './RelativeDate.types'

function RelativeDate ({ value }: IRelativeDateProps) {
  return (
    <Tooltip
      title={moment(value).format('D MMM YYYY, HH:mm')}
      style={{ whiteSpace: 'nowrap' }}
    >
      {value ? moment(value).fromNow() : '-'}
    </Tooltip>
  )
}

export default RelativeDate
