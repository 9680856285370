import * as React from 'react'

import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useIndustries } from '../../hooks/useIndustries'
import { useJobTypes } from '../../hooks/useJobTypes'
import { useNoteTags } from '../../hooks/useNoteTags'
import { useScreeningAuthors } from '../../hooks/useScreeningAuthors'
import { useSkills } from '../../hooks/useSkills'
import { useTechEvalAuthors } from '../../hooks/useTechEvalAuthors'

import { ORIGINS } from '../../utils/role-candidates-helpers'

import ResultsTable from './ResultsTable'

export default function SearchScreen ({ jobId, renderScreenHeader }) {
  const { skillsById } = useSkills()
  const { tagsById } = useNoteTags()
  const { industriesById } = useIndustries()
  const { features } = useFeatureFlags()
  const { screeningAuthorsById } = useScreeningAuthors()
  const { techEvalAuthorsById } = useTechEvalAuthors()
  const { jobTypesById } = useJobTypes()

  if (skillsById && tagsById && industriesById && features && jobTypesById) {
    return (
      <ResultsTable
        skillsById={skillsById}
        tagsById={tagsById}
        industriesById={industriesById}
        screeningAuthorsById={screeningAuthorsById}
        techEvalAuthorsById={techEvalAuthorsById}
        jobTypesById={jobTypesById}
        jobId={jobId}
        renderScreenHeader={renderScreenHeader}
        features={features}
        type={ORIGINS.SEARCH.type}
      />
    )
  }

  return 'loading...'
}
