import React from 'react'

import { AuthConsumer } from '../providers/AuthProvider'

import Layout from '../components/Layout'
import SearchScreen from '../components/SearchScreen'
import SEO from '../components/SEO'

const IndexPage = () => (
  <Layout menuSelectedKeys={['search']}>
    <SEO title="Search" />

    <AuthConsumer>
      {({ isLoading, data }) => {
        if (isLoading) {
          return <div>Loading...</div>
        }

        if (!data) {
          return <div>Not logged in</div>
        }

        return <SearchScreen />
      }}
    </AuthConsumer>
  </Layout>
)

export default IndexPage
